import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { environment } from 'src/environments/environment';

import * as xml2js from 'xml2js';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-invoice-show',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-8 offset-xl-3 mt-4">
        <xng-breadcrumb></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Factura</h1>
            <p>Consulta la información de la factura.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow mb-5">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <form [formGroup]="invoiceForm">
                  <div class="row">
                    <div class="col-md-12">
                      <h3 class="mb-3">Datos de la factura</h3>
                      <div class="form-group mb-4" *ngIf="invoice">
                        <label class="form-label ms-2" for="uuid">ID Documento</label>
                        <input type="text" class="form-control" id="uuid" placeholder="uuid" [value]="invoice.uuid" readonly>
                      </div>
                      <div class="form-group mb-4" *ngIf="invoice">
                        <label class="form-label ms-2" for="uuid">Fecha Timbrado</label>
                        <input type="text" class="form-control" id="FechaTimbrado" placeholder="FechaTimbrado" [value]="invoice.fecha" readonly>
                      </div>
                      <div class="form-group mb-4" *ngIf="invoice">
                        <label class="form-label ms-2" for="uuid">Tipo de factura</label>
                        <input type="text" class="form-control" id="FechaTimbrado" placeholder="FechaTimbrado" value="Factura" readonly>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="serie">Serie</label>
                        <input type="text" class="form-control" id="serie" placeholder="serie" formControlName="serie" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="serie">Folio</label>
                        <input type="text" class="form-control" id="folio" placeholder="folio" formControlName="folio" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="formaPago">Forma de pago</label>
                        <input type="text" class="form-control" id="formaPago" placeholder="formaPago" formControlName="formaPago" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="metodoPago">metodo de pago</label>
                        <input type="text" class="form-control" id="metodoPago" placeholder="metodoPago" formControlName="metodoPago" readonly>
                      </div>
                      <div class="form-group mb-3">
                        <label class="form-label ms-2" for="usoCFDI">Uso del CFDI</label>
                        <input type="text" class="form-control" id="usoCFDI" placeholder="usoCFDI" formControlName="usoCFDI" readonly>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <h5 class="mt-3 mb-3">Receptor</h5>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="rfc">RFC</label>
                        <input type="text" class="form-control" id="rfc" placeholder="rfc" [value]="receptor.Rfc" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="rfc">Nombre</label>
                        <input type="text" class="form-control" id="nombre" placeholder="nombre" [value]="receptor.Nombre" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="DomicilioFiscalReceptor">Domicilio Fiscal Receptor</label>
                        <input type="text" class="form-control" id="DomicilioFiscalReceptor" placeholder="DomicilioFiscalReceptor" [value]="receptor.DomicilioFiscalReceptor" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="rfc">Regimen Fiscal Receptor</label>
                        <input type="text" class="form-control" id="RegimenFiscalReceptor" placeholder="RegimenFiscalReceptor" [value]="receptor.RegimenFiscalReceptor" readonly>
                      </div>
                      <div class="form-group mb-4">
                        <label class="form-label ms-2" for="rfc">Uso CFDI</label>
                        <input type="text" class="form-control" id="UsoCFDI" placeholder="UsoCFDI" [value]="receptor.UsoCFDI" readonly>
                      </div>
                    </div>
                    <!-- div class="col-md-12">
                      {{complemento | json}}
                    </!-->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="invoice">
            <div class="card rounded-4 shadow mb-4" *ngIf="invoice.tipo != 5">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mb-3">Transacción</h3>
                  </div>
                </div>
                <div class="row" *ngIf="!transaction">
                  <div class="col-md-12 text-start">
                    <p class="mb-0">Factura no pagada</p>
                  </div>
                </div>
                <div class="row" *ngIf="transaction">
                  <div class="col-md-2">
                    <div class="badge bg-light py-2 px-2 me-2 border text-center float-start">
                      <img [src]="cardType(transaction?.payment_method_brand)" alt="Card image cap" width="44"> 
                    </div>
                  </div>
                  <div class="col-md-8 ms-2">
                    <h5 class="mb-0"><i># {{transaction?.transaction_id}}</i></h5>
                    <p class="mb-0"><small>Recibo: {{transaction?.bill_id}}</small></p>
                    <p class="mb-0"><small>Método de pago: {{transaction?.payment_method_type}}</small></p>
                    <p class="mb-0"><small>Fecha pago: {{bill?.paid_date | date: 'dd/MM/yy'}}</small></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card rounded-4 shadow mb-4" *ngIf="invoice.tipo != 5">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mb-3">Información general</h3>
                    <p class="mb-0" style="font-size: 14px;">Condominio</p>
                    <h5>{{unitInfo?.settlement?.name}}</h5>
                    <p class="mb-0" style="font-size: 14px;">Edificio</p>
                    <h5>{{unitInfo?.building?.name}}</h5>
                    <p class="mb-0" style="font-size: 14px;">Departamento</p>
                    <h5>{{unitInfo?.unit?.name}}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 rounded-4 shadow mb-4">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mb-3">Resumen</h3>
                    <table class="table table-lg mt-4 border-0">
                      <thead>
                        <tr>
                          <th>Cantidad</th>
                          <th>Clave</th>
                          <th scope="col" class="text-end">IVA</th>
                          <th scope="col" class="text-end">Importe</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="conceptos.length>0">                        
                        <tr *ngFor="let concepto of conceptos">
                          <td>{{concepto['$']['Cantidad']}}</td>
                          <td>{{concepto['$']['ClaveUnidad']}}</td>
                          <td scope="col" class="text-end">{{concepto['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] | currency}}</td>
                          <td scope="col" class="text-end">{{concepto['$']['Importe'] | currency}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-12">
                    <table class="table table-lg mt-4 border-0">
                      <thead>
                        <tr>
                          <td class="text-secondary">Subtotal</td>
                          <td scope="col" class="text-end text-secondary">{{impuestos['Base'] | currency}}</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">IVA</td>
                          <td scope="col" class="text-end text-secondary">{{impuestos['Importe'] | currency}}</td>
                        </tr>
                        <tr class="border-bottom-0">
                          <td class="border-bottom-0">Total</td>
                          <td scope="col" class="text-end border-bottom-0">{{total | currency}}</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow" *ngIf="permissions.modules.admin.invoices.ingreso.write">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mb-3">Acciones</h3>
                    <!-- p>Configura la amenidad con los siguientes campos.</!-->
                    {{invoiceForm}}
                    <form [formGroup]="complementoForm" *ngIf="invoice">
                      <div class="form-group mb-4 mt-2" *ngIf="invoice.pdf">
                        <a href="{{receiptUrl}}/public/{{invoice.pdf}}" target="_blank" class="btn btn-outline-info rounded-5 border border-2 border-info shadow-sm w-100">
                          <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                          Descargar factura
                        </a>
                      </div>
                      <div class="form-group mb-4 mt-2" *ngIf="!invoice.pdf">
                        <a (click)="regenerateInvoice()" target="_blank" class="btn btn-outline-info rounded-5 border border-2 border-info shadow-sm w-100">
                          <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                          Descargar factura
                        </a>
                      </div>
                      <div class="form-group mb-3">
                        <button class="btn btn-outline-info rounded-5 border border-2 border-info shadow-sm w-100" (click)="downloadXML(invoice)"><b>Descargar XML</b></button>
                      </div>

                      <div class="form-group mb-4 mt-3" *ngIf="!related_invoice&&bill.status">
                        <button type="button" (click)="openModal()" class="btn btn-outline-primary rounded-5 w-100">Generar complemento de pago</button>
                      </div>
                      
                      <div class="form-group mb-4 mt-3" *ngIf="related_invoice||invoice.tipo == 1">
                        <div *ngIf="related_invoice.pdf">
                          <a href="{{receiptUrl}}/public/COMPLEMENTO_{{related_invoice.id}}.pdf" target="_blank" class="btn btn-link">
                            <fa-icon [icon]="icons.faFilePdf" class="me-2"></fa-icon>
                            Descargar complemento de pago
                          </a>
                        </div>
                        <div *ngIf="!related_invoice.pdf">
                          <a (click)="regenerateComplement(related_invoice)" target="_blank" class="btn btn-link">
                            <fa-icon [icon]="icons.faFilePdf" class="me-2"></fa-icon>
                            Descargar complemento de pago
                          </a>
                        </div>
                      </div>
                    </form>

                    <form [formGroup]="notaForm" *ngIf="invoice">
                      <div class="form-group mb-4 mt-3" *ngIf="!related_invoice">
                        <button type="button" (click)="openModalNota()" class="btn btn-outline-primary rounded-5 w-100">Generar nota de crédito</button>
                      </div>
                      <div class="form-group mb-4 mt-3" *ngIf="related_invoice">
                        <a href="{{receiptUrl}}/public/FACTURA_{{related_invoice.id}}.pdf" target="_blank" class="btn btn-link">
                          <fa-icon [icon]="icons.faFilePdf" class="me-2"></fa-icon>
                          Descargar nota de crédito
                        </a>
                      </div>
                    </form>
                    <div>
                      <button class="btn btn-danger rounded-5 w-100" (click)="openModalDelete()">
                        <fa-icon [icon]="icons.faTimes" class="me-2"></fa-icon>
                        Cancelar factura
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bill">
      <app-modal-invoice-complement [invoice]="invoice" [bill]="bill" [receptor]="receptor"></app-modal-invoice-complement>
    </div>
    <div>
      <app-modal-invoice-nota [invoice]="invoice" [bill]="bill" [conceptos]="conceptos" [base]="base" [total]="total"></app-modal-invoice-nota>
      <app-modal-invoice-cancel [invoice]="invoice" (invoiceData)="cancelInvoice($event)"></app-modal-invoice-cancel>
    </div>
  `,
  styleUrls: []
})
export class InvoiceShowComponent implements OnInit {
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  invoice: any = null;
  bill: any = null;
  loading: boolean = false;
  transaction: any = null;
  unitInfo: any = null;

  profile: any = {};
  permissions: any = {};

  invoiceForm: FormGroup = new FormGroup({});
  complementoForm: FormGroup = new FormGroup({});
  notaForm: FormGroup = new FormGroup({});

  receptor: any = {};
  complemento: any = {};
  conceptos: any[] = [];
  impuestos: any = {};
  serie: any = null;
  folio: any = null;

  related_invoice: any = null;

  base: number = 0;
  total: number = 0;

  parser: any;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private fb: FormBuilder,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
  }

  ngOnInit(): void {
    let ctl = this;
    this.loadForm();
    this.loadLogo();
    this.activateRoute.params.subscribe(params => {
      this.store.getInvoice(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.invoice = res;
          this.invoiceForm.patchValue(this.invoice);
          console.log('this.invoice X==> ', this.invoice);

          this.parser = new xml2js.Parser({
            trim: true,            
            explicitArray: true,            
          });
          this.parser.parseString(this.invoice.cfdixml, function (err: any, result: any) {
            console.log('STARTA =========');
            console.log('result => ', result);
            console.log('receptor ==> ', result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']);
            ctl.serie = result['cfdi:Comprobante']['$']['Serie'];
            ctl.folio = result['cfdi:Comprobante']['$']['Folio'];
            ctl.receptor = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
            ctl.complemento = result['cfdi:Comprobante']['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$'];
            ctl.conceptos = result['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'];            
            ctl.impuestos = result['cfdi:Comprobante']['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'];
            ctl.base = Number(ctl.impuestos['Base']);
            ctl.total = Number(ctl.impuestos['Base']) + Number(ctl.impuestos['Importe']);
            console.log("ctl.total => ", ctl.total);
            console.log('err => ', err);
          });

          if (this.invoice.tipo == 5) {
            this.breadcrumbService.set('@invoiceShow', this.invoice.id);
            return;
          } else {
            this.store.getBillByInvoice(this.invoice.id).subscribe((bill: any) => {
              console.log('bill => ', bill);
              this.bill = bill;

              this.store.getUnitSettlement(bill.unit_id).subscribe((unit: any) => {
                console.log('unit => ', unit);
                this.unitInfo = unit;
              });

              this.store.getTransactionByBill(bill.id).subscribe((transaction: any) => {
                console.log('transaction => ', transaction);
                this.transaction = transaction;
              });
            });
          }

          if (this.invoice.related_invoice) {
            this.store.getInvoice(this.invoice.related_invoice).subscribe((res: any) => {
              this.related_invoice = res;
            });
          }

          console.log('this.invoice.cfdixml => ', this.invoice.cfdixml);

          
          this.breadcrumbService.set('@invoiceShow', this.complemento.UUID);
          console.log("this.conceptos => ", this.conceptos);
        },
        error: (err: any) => {
          this.loading = false;
          this.toastr.error(err.error.message, 'Error');
        }
      });
    });
  }

  async loadLogo() {
    const img = this.images.buildURL(this.profile.logo);
    const imx: any = await this.images.getBase64ImageFromUrl(img);
    this.profile.logo = `${imx.split(',')[1]}`;
  }

  loadForm() {
    this.invoiceForm = this.fb.group({
      serie: [''],
      formaPago: [''],
      metodoPago: [''],
      usoCFDI: [''],
      folio: [''],
    });
  }

  initComplemento() {
    this.complementoForm = this.fb.group({})
  }

  initNota() {
    this.notaForm = this.fb.group({});
  }

  cardType(card: any) {
    if (card == 'VI') {
      return "/assets/images/icon-visa-sm.png";
    } else if (card == 'MC') {
      return "/assets/images/icon-mastercard-sm.png";
    } else if (card == 'AMEX') {
      return "/assets/images/icon-amex-sm.png";
    } else if (card == 'CASH') {
      return "/assets/images/icon-cash.png";
    } else {
      return "/assets/images/icon-visa-sm.png";
    }
  }

  downloadXML(invoice: any) {
    const blob2 = new Blob([invoice.cfdixml], { type: 'application/xml' });

    var fileURL2 = window.URL.createObjectURL(blob2);
    var link2=document.createElement('a');

    link2.href = fileURL2;
    link2.download = `${invoice.serie}_${invoice.folio}.xml`;
    link2.click();
  }

  openModal() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalInvoiceComplement')).show();
  }

  openModalNota() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalInvoiceNota')).show();
  }

  openModalDelete() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalDeleteInvoice')).show();
    // bs.Modal.getOrCreateInstance(<HTMLInputElement>document.getElementById('modalDeleteInvoice')).show();
    // bs.Modal.getOrCreateInstance('#modalDeleteInvoice').show();
  }

  cancelInvoice(event: any) {    
    this.store.cancelInvoice({
      profile: this.profile,
      id: this.invoice.id,
      reason: event
    }).subscribe({
      next: (res: any) => {
        console.log(res);
        this.toastr.success('Factura cancelada correctamente.', 'Éxito');
        // this.router.navigate(['/invoices/canceladas']);
        window.location.reload();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al cancelar la factura.', 'Error');
      }
    });
  }

  regenerateComplement(related_invoice: any) {
    this.store.downloadComplement({
      invoiceData: related_invoice,
      utilityData: this.profile
    }).subscribe({
      next: (data: any) => {
        console.log(data);
        this.loading = false;

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([this.invoice.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${this.serie}_${this.folio}.xml`;
        link2.click();

        this.toastr.success('Factura descargada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        if (err.status >= 500) {
          this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        }
        this.loading = false;
      }
    });
  }

  regenerateInvoice() {
    this.store.downloadGenericInvoice({
      invoiceData: this.invoice,
      utilityData: this.profile
    }).subscribe({
      next: (data: any) => {
        console.log(data);
        this.loading = false;

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([this.invoice.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${this.serie}_${this.folio}.xml`;
        link2.click();

        this.toastr.success('Factura descargada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        if (err.status >= 500) {
          this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        }
        this.loading = false;
      }
    });
  }
}
