import { Component, Input } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-invoice-nota',
  template: `
    <div class="row">
      <div class="col-md-8">
        <h4 class="mb-0">Certificado #: {{invoice.noCertificado}}</h4>
        <p class="mb-0">Serie: {{invoice.serie}} Folio: {{invoice.folio}}</p>
        <p class="mb-0" class="mb-0 text-sm text-muted">Creado: {{invoice.created_at | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-md-4 clearfix">
        <div class="float-end">
          <!-- div class="badge rounded-pill me-3 px-3 py-2 border border-3" [ngClass]="invoice.status == 'active' ? 'border-success text-success' : 'border-warning text-warning'">
            <p class="mb-0 text-capitalize">{{invoice.status}}</p>
          </!-->
          <a class="btn btn-outline-dark rounded-4 btn-lg text-end" href="{{receiptUrl}}/public/{{ invoice.pdf }}" target="_blank">
            <fa-icon [icon]="faDownload"></fa-icon>
            Descargar
          </a>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemInvoiceNotaComponent {
  @Input() invoice: any;

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;

  faDownload = faDownload;
}
