import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transactions-payments',
  template: `
    <div class="row">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">
        <div class="row mb-2">
          <div class="col-md-5 col-lg-5">
            <h1>Recibos no emitidos</h1>
            <p>Consulta los recibios no emitidos.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->            
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          (billsSettlement)="loadBillsBySettlement($event)"
          (billsBuilding)="loadBillsByBuilding($event)"
          (billsFloor)="loadBillsByFloor($event)"
          (billsUnit)="loadBillsByUnit($event)"
          (billsSort)="loadBillsSort($event)"
          (billsOrder)="loadBillsOrder($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></app-filter-full>
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="list-group rounded-4 shadow mt-2" *ngIf="bills.length > 0">
              <div class="list-group-item bg-white" *ngFor="let bill of bills | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries}">
                <app-item-bill-pending [bill]="bill" [profile]="profile" [permissions]="permissions"></app-item-bill-pending>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="bills.length==0">
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="180px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
              <div *ngIf="loading" class="text-center m-3">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-5" >
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="bills.length>0"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TransactionsPaymentsComponent implements OnInit {
  bills: any = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  
  searchBox: FormGroup = new FormGroup({});
  // searchActive: boolean = false;
  loading: boolean = false;

  payload: any = {    
    page: 1,
    per_page: 10,
    status: false,
    state: 0,
    invoiced: false,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  p: number = 1;
  units: any = [];

  constructor(
    private store : SettlementsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile = this.profile.id;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
  }

  ngOnInit(): void {
    this.loadBills(this.payload);
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadBills(this.payload);
    }
  }

  loadBills(payload: any) {
    this.store.getBillsQuery(payload).subscribe((data: any) => {
      this.bills = data.data;
      this.meta = data.meta;
    });
  }

  loadBillsBySettlement(settlement_id: any) {
    this.store.getBillsBySettlement(settlement_id, this.payload).subscribe((data: any) => {
      this.bills = data.data;
      this.meta = data.meta;
    });    
  }

  loadBillsByBuilding(building_id: any) {
    // this.store.getBillsByBuilding(building_id, this.payload).subscribe((data: any) => {
      // this.bills = data.data;
      // this.meta = data.meta;
    // });
  }

  loadBillsByFloor(floor_id: any) {
    // this.store.getBillsByFloor(floor_id, this.payload).subscribe((data: any) => {
      // this.bills = data.data;
      // this.meta = data.meta;
    // });
  }

  loadBillsByUnit(unit_id: any) {
    this.store.getBillsByUnit(unit_id, this.payload).subscribe((data: any) => {
      this.bills = data.data;
      this.meta = data.meta;
    });
  }

  loadBillsSort(event: any) {
    console.log('SORT EVENT ==> ', event);
    this.payload.sort = event;
    // this.payload.order = event.order;
    // this.loadBills(this.payload);
    this.getCurrentFilter();
  }

  loadBillsOrder(event: any) {
    console.log('ORDER EVENT ==> ', event);
    this.payload.order = event;
    this.getCurrentFilter();
  }

  getCurrentFilter() {
    console.log('CURRENT FILTER ==> ', this.payload);
    let count = 0;
    this.route.queryParamMap.subscribe(params => {      
      if (count>0){return;}
      console.log('PARAMS ==> ', params);

      if (params.has('unidad')) {
        this.payload.unit_id = params.get('unidad');
        this.loadBillsByUnit(this.payload.unit_id);
        return;
      }

      if (params.has('piso')) {
        this.payload.floor_id = params.get('piso');
        this.loadBillsByFloor(this.payload.floor_id);
        return;
      }

      if (params.has('edificio')) {
        this.payload.building_id = params.get('edificio');
        this.loadBillsByBuilding(this.payload.building_id);
        return;
      }

      if (params.has('condominio')) {
        this.payload.settlement_id = params.get('condominio');
        this.loadBillsBySettlement(this.payload.settlement_id);
        return;
      }
      count++;
    });
  }

  clearFilter() {    
    console.log('CLEAR FILTER ==> ', this.payload);
    this.loadBills(this.payload);
  }

  searchUsers(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadBills(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    // this.loadBills(this.payload);
    this.getCurrentFilter();
  }

}
