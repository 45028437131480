import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ValidateConfirmpass } from 'src/app/components/confirmpass.validator';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-tenant-profile',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-3 mt-sm-5">
        <div class="row">
          <div class="col-12 col-sm-5 col-md-5">
            <h1>Perfil</h1>
            <p>Configura tu información.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar usuario</!-->
          </div>
        </div>
      </div>
      
      <div class="col-10 offset-1 col-sm-12 col-md-4 offset-sm-0 offset-md-3 mt-2 mt-sm-4">
        <div class="card rounded-5 shadow mb-5"> 
          <div class="card-body p-3 p-sm-4">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)">
                  <fieldset>
                    <legend class="mt-2 mb-4" style="font-size: 19px; font-weight: 800;">Datos personales</legend>
                    <div class="avatar-container text-center m-auto">
                      <div class="image-container">
                        <img [src]="avatarURL" width="100%">
                      </div> 
                      <label for="avatar-input-file">
                        <div class="icon-wrapper">
                          <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
                          <fa-icon [icon]="icons.faEdit" class="text-white"></fa-icon>
                        </div>
                      </label>
                    </div>
                    <div class="form-group mt-3">
                      <label for="name">Nombre(s)</label>
                      <input type="text" formControlName="name" class="form-control form-control-lg rounded-3" id="name" placeholder="Nombre">
                      <div *ngIf="tenantForm['name'].touched && tenantForm['name'].invalid">
                        <div *ngIf="tenantForm['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
                      </div>
                    </div>  
                    <div class="form-group mt-3">
                      <label for="last_name">Apellidos</label>
                      <input type="text" formControlName="last_name" class="form-control form-control-lg rounded-3" id="last_name" placeholder="Apellidos">
                      <div *ngIf="tenantForm['last_name'].touched && tenantForm['last_name'].invalid">
                        <div *ngIf="tenantForm['last_name'].errors?.['required']" class="text-danger text-sm">Los apellidos es requerido.</div>
                      </div>
                    </div>  
                    <div class="form-group mt-3">
                      <label for="dob">Fecha de nacimiento</label>
                      <input type="date" formControlName="dob" class="form-control form-control-lg rounded-3" id="dob" placeholder="Apellidos">
                      <div *ngIf="tenantForm['dob'].touched && tenantForm['dob'].invalid">
                        <div *ngIf="tenantForm['dob'].errors?.['required']" class="text-danger text-sm">La fecha de nacimiento es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="emailInput">Correo electrónico</label>
                      <input type="email" formControlName="email" class="form-control form-control-lg rounded-3" id="emailInput" placeholder="Correo electrónico">
                      <div *ngIf="tenantForm['email'].touched && tenantForm['email'].invalid">
                        <div *ngIf="tenantForm['email'].errors?.['required']" class="text-danger text-sm">El correo es requerido.</div>
                        <div *ngIf="tenantForm['email'].errors?.['email']" class="text-danger text-sm">El correo no es válido.</div>
                      </div>
                    </div>  
                    <div class="form-group mt-3">
                      <label for="phone">Teléfono</label>
                      <input type="tel" formControlName="phone" class="form-control form-control-lg rounded-3" id="phone" placeholder="Teléfono">
                      <div *ngIf="tenantForm['phone'].touched && tenantForm['phone'].invalid">
                        <div *ngIf="tenantForm['phone'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
                        <div *ngIf="tenantForm['phone'].errors?.['minlength']" class="text-danger text-sm">El teléfono no es válido (10 dígitos).</div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend class="mt-5 mb-4" style="font-size: 19px; font-weight: 800;">Dirección</legend>
                    <div class="form-group mt-3">
                      <label for="street">Calle</label>
                      <input type="text" formControlName="street" class="form-control form-control-lg rounded-3" id="street">
                      <div *ngIf="tenantForm['street'].touched && tenantForm['street'].invalid">
                        <div *ngIf="tenantForm['street'].errors?.['required']" class="text-danger text-sm">La calle es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="state">Estado</label>
                      <select class="form-select form-select-lg" id="state" formControlName="state">
                        <option *ngFor="let state of catalogs.edos" [value]="state.value">{{state.name}}</option>
                      </select>
                      <div *ngIf="tenantForm['state'].touched && tenantForm['state'].invalid">
                        <div *ngIf="tenantForm['state'].errors?.['required']" class="text-danger text-sm">El estado es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="city">Ciudad</label>
                      <input type="text" formControlName="city" class="form-control form-control-lg rounded-3" id="city">
                      <div *ngIf="tenantForm['city'].touched && tenantForm['city'].invalid">
                        <div *ngIf="tenantForm['city'].errors?.['required']" class="text-danger text-sm">La ciudad es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="zip_code">Código postal</label>
                      <input type="text" formControlName="zip_code" class="form-control form-control-lg rounded-3">
                      <div *ngIf="tenantForm['zip_code'].touched && tenantForm['zip_code'].invalid">
                        <div *ngIf="tenantForm['zip_code'].errors?.['required']" class="text-danger text-sm">El Código Postal es requerido.</div>
                      </div>
                    </div>
                  </fieldset>
                  <div class="form-group mt-5 text-end">
                    <button class="btn btn-outline-primary btn-lg rounded-5">Actualizar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-sm-4 col-md-4 mt-2 mt-sm-4 mb-5">        
        <div class="card rounded-5 shadow border-0 overflow-hidden">
          <div class="card-header bg-white border-bottom-0 pb-0 pt-4 ps-4 pe-5">
            <ul class="nav nav-tabs" #tabs>
              <li class="nav-item">
                <a #menu1 class="nav-link active" data-toggle="tab" href="#address" (click)="tab($event)" >Contraseña</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#fiscal" (click)="tab($event)">Datos físcales</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#public_record" (click)="tab($event)">Registro público</a>
              </li>
              <li class="nav-item" *ngIf="public_record !== null">
                <a class="nav-link" data-toggle="tab" href="#representante" (click)="tab($event)">Representante legal</a>
              </li>
            </ul>
          </div>
          <div class="card-body p-3 p-sm-4">
            <div #tabContent class="tab-content">
              <div id="address" class="tab-pane address active ps-3 pe-3">
                <form [formGroup]="userPwdForm" (ngSubmit)="updatePassword()">
                  <fieldset>
                    <legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Cambiar contraseña</legend>
                  <div class="form-group mt-3">
                    <label for="password" class="form-control-label">Contraseña</label>
                    <input type="password" class="form-control form-control-lg rounded-3" id="password" formControlName="password" placeholder="Contraseña">
                    <div *ngIf="userPwd['password'].touched && userPwd['password'].invalid" class="text-start">
                      <div *ngIf="userPwd['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
                      <small *ngIf="userPwd['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
                      <small *ngIf="userPwd['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
                      <small *ngIf="!userPwd['password'].errors?.['minlength'] && userPwd['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($@#!%*?&)</small>
                    </div>
                  </div>

                  <div class="form-group mt-3">
                    <label for="password_confirmation" class="form-control-label rounded-3">Confirmar Contraseña</label>
                    <input type="password" class="form-control form-control-lg" id="password_confirmation" formControlName="password_confirmation" placeholder="Repetir contraseña">
                    <div *ngIf="userPwd['password_confirmation'].touched && userPwd['password_confirmation'].invalid" class="text-start">
                      <div *ngIf="userPwd['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
                      <small *ngIf="userPwd['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
                    </div>
                  </div>

                  <div class="form-group mt-5 text-end">
                    <button type="submit" class="btn btn-warning btn-lg rounded-5">Actualizar</button>
                  </div>
                </fieldset>
                </form>
              </div>
              <div id="fiscal" class="tab-pane fiscal ps-3 pe-3">
                <form [formGroup]="userFiscalForm" (ngSubmit)="updateFiscal()">
                  <fieldset>
                    <legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Datos fiscales</legend>
                    <div class="form-group mt-3">
                      <input class="form-check-input form-check-input-lg" type="checkbox" id="flexCheckDefault" formControlName="periodic">
                      <label class="form-check-label ms-3" for="flexCheckDefault">
                        Solicitar factura al generar recibo
                      </label>
                    </div>
                    <div class="form-group mt-3">
                      <label for="rfc">RFC</label>
                      <input type="text" class="form-control form-control-lg rounded-3" id="rfc" formControlName="rfc">
                      <div *ngIf="ffc['rfc'].touched && ffc['rfc'].invalid">
                        <div *ngIf="ffc['rfc'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="razon_social">Razón social</label>
                      <input type="text" class="form-control form-control-lg rounded-3" id="razon_social" formControlName="razon_social">
                      <div *ngIf="ffc['razon_social'].touched && ffc['razon_social'].invalid">
                        <div *ngIf="ffc['razon_social'].errors?.['required']" class="text-danger text-sm">La razón social es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="zip_code">Código postal</label>
                      <input type="text" class="form-control form-control-lg rounded-3" id="zip_code" formControlName="zip_code">
                      <div *ngIf="ffc['zip_code'].touched && ffc['zip_code'].invalid">
                        <div *ngIf="ffc['zip_code'].errors?.['required']" class="text-danger text-sm">El Código postal es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="regimen_fiscal">Regímen fiscal</label>
                      <select class="form-select form-select-lg" formControlName="regimen_fiscal">
                        <option value="" selected>Selecciona un régimen fiscal</option>
                        <option *ngFor="let regimen of catalogs.regimenes" [value]="regimen.id">{{regimen.id}} - {{regimen.name}}</option>
                      </select>
                      <div *ngIf="ffc['regimen_fiscal'].touched && ffc['regimen_fiscal'].invalid">
                        <div *ngIf="ffc['regimen_fiscal'].errors?.['required']" class="text-danger text-sm">El Regimen fiscal es requerido.</div>
                      </div>
                    </div>
                    
                    <div class="form-group mt-3">
                      <label for="uso_cfdi">Uso CFDI</label>
                      <select class="form-select form-select-lg" formControlName="uso_cfdi">
                        <option value="" selected>Selecciona un uso de CFDI</option>
                        <option *ngFor="let usoCFDI of catalogs.usosCFDI" [value]="usoCFDI.id">{{usoCFDI.id}} - {{usoCFDI.name}}</option>
                      </select>
                      <div *ngIf="ffc['uso_cfdi'].touched && ffc['uso_cfdi'].invalid">
                        <div *ngIf="ffc['uso_cfdi'].errors?.['required']" class="text-danger text-sm">El Uso de CFDI es requerido.</div>
                      </div>
                    </div>

                    <div class="form-group mt-5 mb-2 text-end">
                      <button type="submit" class="btn btn-outline-primary btn-lg rounded-5">Guardar</button>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div id="public_record" class="tab-pane public_record ps-3 pe-3">
                <app-form-public-record [publicRecord]="publicRecord" (publicRecordData)="createPublicRecord($event)"></app-form-public-record>
              </div>
              <div id="representante" class="tab-pane representante ps-3 pe-3" *ngIf="public_record!==null">
                <app-form-public-record [publicRecord]="legal_rep" [isLegal]="true" [related]="public_record.id" (publicRecordData)="createLegalRep($event)"></app-form-public-record>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  `,
  styleUrls: []
})
export class TenantProfileComponent implements OnInit {
  @ViewChild('tabs') tabs!: ElementRef | undefined;
  @ViewChild('tabContent') tabContent!: ElementRef | undefined;
  user: any = {};
  avatarURL: any = '';
  userId: number = 0;
  userForm: FormGroup = new FormGroup({});
  userPwdForm: FormGroup = new FormGroup({});
  userFiscalForm: FormGroup = new FormGroup({});
  addressData: any = {};

  publicRecord: any = null;
  public_record: any = null;
  legal_rep: any = null;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}';

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public catalogs: CatalogsService,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.activateRoute.params.subscribe(params => {
      this.userId = params['id'];
      this.store.getTenant(this.userId).subscribe((data: any) => {
        this.userForm.patchValue(data);
        console.log('data => ', data);
        if (data.avatar) {
          this.images.buildURL(data.avatar);
        }
        this.store.getTenantAddress(this.userId).subscribe((address: any) => {
          this.addressData = address;
          console.log('TENANT address => ', address);
          if (address != null) {
            this.userForm.patchValue({
              street: address.address,
              state: address.state,
              city: address.city,
              zip_code: address.zip_code            
            });
          }
        });
        this.store.getTenantFiscal(this.userId).subscribe({
          next: (fiscal: any) => {
          this.userFiscalForm.patchValue(fiscal);
          }, error: (err: any) => {
            console.log('no fiscal data');
          }
        });
        this.store.getPublicRecord(data.public_record_id).subscribe((publicRecord: any) => {
          console.log('load PUBLIC RECORD => ', publicRecord);
          this.publicRecord = publicRecord;
        });
      });
    });

  }

  ngOnInit(): void {
    this.buildForm();
    this.buildPwdForm();
    this.buildFiscalForm();
    // this.loadUser(this.userId);
  }

  tab(e: any) {
    e.preventDefault();
    //
    this.tabs?.nativeElement.querySelectorAll('.nav-link').forEach((tab: any) => {
      tab.classList.remove('active');
    });
    this.tabContent?.nativeElement.querySelectorAll('.tab-pane').forEach((tabPane: any) => {
      tabPane.classList.remove('active');
    });
    e.target.classList.add('active');
    this.tabContent?.nativeElement.querySelector(`.${e.target.href.split('#')[1]}`)?.classList.add('active');
  }

  buildForm() {
    this.userForm = this.fb.group({
      id: [''],
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      dob: ['', Validators.required],
      street: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      country: ['MX', Validators.required],
      zip_code: ['', Validators.required, Validators.minLength(5)]
    });
  }

  buildPwdForm() {
    this.userPwdForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.passwordPattern)]],
      password_confirmation: ['', [Validators.required, ValidateConfirmpass]]
    });
  }

  buildFiscalForm() {
    this.userFiscalForm = this.fb.group({
      id: [''],
      periodic: [false],
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      zip_code: ['', Validators.required, Validators.minLength(5)],
      regimen_fiscal: ['', Validators.required],
      uso_cfdi: ['', Validators.required],
      tenant_id: [this.userId]
    });
  }

  get userPwd() {
    return this.userPwdForm.controls;
  }

  get ffc() {
    return this.userFiscalForm.controls;
  }

  get tenantForm() {
    return this.userForm.controls;
  }

  loadUser(id: number) {
    this.store.getTenant(this.userId).subscribe((data: any) => {
      console.log('data => ', data);
      let el = data.avatar;

        this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
        console.log("avatarURL => ", this.avatarURL);
    });
  }

  loadAvatar(event: any): void {

    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        // return false;
      }

      // if (!_.includes(allowed_types, event.target.files[0].type)) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        // return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            let payload = {
              id: this.userId,
              data: this.previewImagePath
            }
            console.log('event => ', event.target.files[0]);
            this.store.updateTenantAvatar(this.userForm.value.id, payload).subscribe({
              next: (data: any) => {
                this.toastr.success('Avatar actualizado correctamente.');
                this.loadUser(this.userId);
              },
              error: (err: any) => {
                console.log(err);
                this.toastr.error('Ocurrió un error al actualizar el avatar.');
              }
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }    
  }

  onSubmit(form: any) {
    console.log("update profile => ", form);
    console.log('Address data => ', this.addressData);

    const profile = {
      id: this.userId,
      name: form.value.name,
      last_name: form.value.last_name,
      phone: form.value.phone,
      email: form.value.email,
      dob: form.value.dob
    };
    const profile_address = {
      id: this.addressData ? this.addressData.id : null,
      country: 'MX',
      address: form.value.street,
      state: form.value.state,
      city: form.value.city,
      zip_code: form.value.zip_code,
      tenant_id: this.userId
    };

    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.toastr.error('No está completa la información.', 'Error');
      return;
    }

    this.store.updateTenant(this.userId, profile).subscribe((data: any) => {
      if (this.addressData == null) {
        this.store.createTenantAddress(profile_address).subscribe((data: any) => {
          console.log('data => ', data);
          // this.route.navigate(['/tenant']);
          this.toastr.success('Perfil actualizado correctamente.');
        });
      } else {
        this.store.updateTenantAddress(profile_address.id, profile_address).subscribe((data: any) => {
          console.log('data => ', data);
          this.toastr.success('Perfil actualizado correctamente.');
        });
      }
    });
  }

  updatePassword() {
    console.log(this.userPwdForm);
    if (this.userPwdForm.invalid) {
      this.userPwdForm.markAllAsTouched();
      this.toastr.error('No está completa la información.', 'Error');
      return;
    }

    this.store.updateTenant(this.userId, this.userPwdForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Contraseña actualizada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Error al actualizar la contraseña.', 'Error');
      }
    });

    // this.updatePassword
  }

  updateFiscal() {
    if (this.userFiscalForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.userFiscalForm.markAllAsTouched();
      return;
    }

    console.log('userFiscalForm => ', this.userFiscalForm.value);

    if (this.userFiscalForm.value.id == '') {
      this.store.createTenantFiscal(this.userFiscalForm.value).subscribe({
        next: (data: any) => {
          this.toastr.success('Datos fiscales actualizados correctamente.', 'Éxito');
        },
        error: (err: any) => {
          this.toastr.error('Error al actualizar los datos fiscales.', 'Error');
        }
      });
    } else {
      this.store.updateTenantFiscal(this.userFiscalForm.value.id, this.userFiscalForm.value).subscribe({
        next: (data: any) => {
          this.toastr.success('Datos fiscales actualizados correctamente.', 'Éxito');
        },
        error: (err: any) => {
          this.toastr.error('Error al actualizar los datos fiscales.', 'Error');
        }
      });
    }
  }

  createPublicRecord(event: any) {
    console.log("create ", event);

    const payload: any = event;
    payload.profile_id = this.user.id;

    if (event.id) {
      this.store.updatePublicRecord(event.id, event).subscribe({
        next: (res: any) => {
          console.log('updatePublicRecord res', res);
          this.toastr.success('El registro público se actualizó correctamente.', 'Actualizado');
          this.store.updateTenant(this.userId, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
          });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el registro público.', 'Error');
        }
      });
    } else {
      this.store.createPublicRecord(event).subscribe({
        next: (res: any) => {
          console.log('createPublicRecord res', res);
          this.toastr.success('El registro público se creó correctamente.', 'Creado');
          this.store.updateTenant(this.userId, { public_record_id: res.id }).subscribe((data: any) => {
            // this.router.navigate(['/perfiles']);
          });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al crear el registro público.', 'Error');
        }
      });
    }
  }

  createLegalRep(event: any) {
    const payload: any = event;
    payload.profile_id = this.user.id;

    if (event.id) {
      this.store.updatePublicRecord(event.id, event).subscribe({
        next: (res: any) => {
          console.log('updatePublicRecord res', res);
          this.toastr.success('El registro público se actualizó correctamente.', 'Actualizado');
          // this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
          // });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el registro público.', 'Error');
        }
      });
    } else {
      this.store.createPublicRecord(event).subscribe({
        next: (res: any) => {
          console.log('createPublicRecord res', res);
          this.toastr.success('El registro público se creó correctamente.', 'Creado');
          // this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
            // this.router.navigate(['/perfiles']);
          // });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al crear el registro público.', 'Error');
        }
      });
    }
  }
}
